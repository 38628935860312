import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { AnswerObjectionsSection } from '../../../components/AnswerObjectionsSection';
import { BenefitsList } from '../../../components/BenefitsList';
import { FinalCTA } from '../../../components/FinalCTA';
import { HeroSection } from '../../../components/HeroSection';
import { Integrations } from '../../../components/Integrations';
import { Layout } from '../../../components/Layout';
import { BenefitsSection } from '../../../components/pages/features/client-identity/BenefitsSection';
import { ProblemSection } from '../../../components/pages/features/client-identity/ProblemSection';
import { ProblemSolutionSummary } from '../../../components/pages/features/client-identity/ProblemSolutionSummary';
import { PricingSection } from '../../../components/PricingSection';
import { QRScanSection } from '../../../components/QRScanSection';
import { SEO } from '../../../components/SEO';
import { TestimonialSection } from '../../../components/TestimonialSection';

const benefits = [
  {
    heading: 'Easy setup',
    body: 'Setup in 10 minutes and avoid costly implementations. Canyou integrates with your existing systems so you can keep using your existing payroll and time systems.',
  },
  {
    heading: 'Easy for the team',
    body: 'Team members use Canyou from their own device. Set reminders with Canyou to send instant notifications when the team needs to interact.',
  },
  {
    heading: 'Everything in one spot',
    body: 'Canyou is the single place for your team to keep track of the status of training, compliance and operations tasks',
  },
  {
    heading: 'Reminders and notifications',
    body: 'Setup reminders and notifications to keep track of important dates like licence expiries or annual pay reviews. Notifications get sent out to the team members to make sure you never miss anything',
  },
  {
    heading: 'Safe and secure',
    body: 'Transfer sensitive data safely and securely. No more data leak risks through emails and text messages',
  },
];

type Data = {
  heroImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const ClientIdentityVerification: React.FC = () => {
  const { heroImage } = useStaticQuery<Data>(
    graphql`
      {
        heroImage: file(
          relativePath: {
            eq: "screenshots/client-identification-dashboard.png"
          }
        ) {
          childImageSharp {
            gatsbyImageData(width: 900, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );
  return (
    <Layout>
      <SEO
        title="Automated client identity verification"
        description="Automated client identity verification. Collect proof of identity requirements for client verification."
      />
      <HeroSection
        heading="Simple, Affordable Client Identity Verification"
        subHeading="Identify individuals, organisations and their representatives via secure document transfer. Minimising compliance and security risks for both you and your clients."
        heroImage={
          <GatsbyImage
            alt="client identity verification app"
            image={heroImage.childImageSharp.gatsbyImageData}
            style={{
              maxWidth: `90vh`,
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
        }
      />
      <Integrations isMyobLogoDisplayed bgColor="brand.egg" />
      <ProblemSolutionSummary />
      <ProblemSection />
      <BenefitsSection />
      <BenefitsList benefits={benefits} />
      <TestimonialSection />
      <PricingSection />
      <AnswerObjectionsSection />
      <QRScanSection />
      <FinalCTA />
    </Layout>
  );
};

export default ClientIdentityVerification;
