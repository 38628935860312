import * as React from 'react';
import { Heading, Box, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ProblemBulletList } from '../licence-and-certs/ProblemSection/ProblemBulletList';

const problemList: string[] = [
  'You know that emails and text messages are not safe or secure',
  'You need to collect different documents depending on the client',
  'Manual processes are reducing your time',
  'You want to look more professional to your clients',
];

type Data = {
  ladyCheckLicences: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const ProblemSection: React.FC = () => {
  const { ladyCheckLicences } = useStaticQuery<Data>(
    graphql`
      {
        ladyCheckLicences: file(
          relativePath: { eq: "problems/lady-checking-licences.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );
  return (
    <Box py={{ base: '35px', lg: '90px' }}>
      <Heading
        as="h3"
        fontFamily="roboto"
        fontWeight="500"
        fontSize={{ base: '24px', md: '36px' }}
        textAlign={{ base: 'center' }}
        pb="12px"
        px={4}
      >
        Does this sound familiar?
      </Heading>
      <Heading
        as="h2"
        size="lg"
        textAlign="center"
        px={4}
        pb={{ base: '55px' }}
      >
        <Box as="span">I need to </Box>
        <Box as="span" color="brand.blue">
          identify{' '}
        </Box>
        <Box as="span">
          my clients <br />
          but my process is manual
        </Box>
      </Heading>
      <Flex
        direction={{
          base: 'column',
          lg: 'row',
        }}
        justify="space-around"
        align="center"
        px={{ base: 0, xl: '130px' }}
        gridGap="6"
      >
        <Box>
          <GatsbyImage
            alt="Lady checking licences"
            image={ladyCheckLicences.childImageSharp.gatsbyImageData}
            style={{
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
        </Box>
        <ProblemBulletList problemList={problemList} />
      </Flex>
    </Box>
  );
};
