import { Heading, Box, Text, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { BenefitDetails, BenefitSection } from '../../../BenefitSection';
import CanyouLogo from '../../../../assets/canyou-blue-logo.svg';

type Data = {
  collectIdentityDocuments: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  clientDevice: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  uploadDriverLicence: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  identityDashboard: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  identityStatus: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const BenefitsSection: React.FC = () => {
  const {
    collectIdentityDocuments,
    identityDashboard,
    uploadDriverLicence,
    clientDevice,
    identityStatus,
  } = useStaticQuery<Data>(
    graphql`
      {
        identityDashboard: file(
          relativePath: { eq: "screenshots/identity-dashboard.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
          }
        }
        identityStatus: file(
          relativePath: { eq: "screenshots/identity-status.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 280, layout: CONSTRAINED, quality: 90)
          }
        }
        collectIdentityDocuments: file(
          relativePath: { eq: "screenshots/collect-identity-documents.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
          }
        }
        digitalLicenceSubmission: file(
          relativePath: { eq: "screenshots/digital-licence-submission.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
          }
        }
        clientDevice: file(
          relativePath: { eq: "screenshots/qr-code-employee-device.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
          }
        }
        uploadDriverLicence: file(
          relativePath: { eq: "screenshots/upload-driver-licence.png" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    `,
  );

  const benefitDetails: BenefitDetails[] = [
    // Need an updated image here
    //
    // {
    //   heading: 'Protects you and your clients',
    //   subHeading:
    //     'Remove the risk of data leaks via email and text message. Ensure compliance with identity verification requirements',
    //   body: 'Securely transmit client data using our secure platform. Once identities are confirmed all data is obfuscated for extra security. All Canyou data is stored in Australia.',
    //   isOrderReversed: true,
    //   images: [
    //     {
    //       gatsbyImageData: smartWorkflows.childImageSharp.gatsbyImageData,
    //       alt: 'protect your clients',
    //     },
    //   ],
    // },
    {
      heading: 'Automated collection of Identity documents',
      subHeading:
        'Easily identify individuals, organisations and any representatives',
      body: 'Canyou requests appropriate documentation identifying individuals, sole-traders, partnerships or companies and representatives.',
      images: [
        {
          gatsbyImageData:
            collectIdentityDocuments.childImageSharp.gatsbyImageData,
          alt: 'Licence certification dashboard',
        },
      ],
    },
    {
      heading: 'No more emails or text messages',
      subHeading: 'Reduce time spent on manual processes',
      body: "It's hard to avoid errors with email and text messages. Missing fields, wrong information and constant checking of the inbox. When you request data via Canyou you can feel confident it’s correct.",
      isOrderReversed: true,
      images: [
        {
          gatsbyImageData: identityDashboard.childImageSharp.gatsbyImageData,
          alt: 'Identity dashboard',
        },
        {
          gatsbyImageData: identityStatus.childImageSharp.gatsbyImageData,
          alt: 'Identity status',
        },
      ],
    },
    {
      heading: 'Easy for clients',
      subHeading:
        'No passwords required. Upload requirements from any device. You look professional.',
      body: 'Clients can submit an ID check from any device with a web browser. No need to setup a password to get started making the process streamlined and user friendly.',
      images: [
        {
          gatsbyImageData: clientDevice.childImageSharp.gatsbyImageData,
          alt: 'Digital licence submission',
        },
      ],
    },
    {
      heading: 'Digital Submission from any device',
      subHeading: "Conveniently submit what's needed.",
      body: 'Digital completion via the clients device with automatic validations.',
      isOrderReversed: true,
      images: [
        {
          gatsbyImageData: uploadDriverLicence.childImageSharp.gatsbyImageData,
          alt: 'upload proof of identity',
        },
      ],
    },
  ];

  return (
    <Box
      pb={{ base: '35px', lg: '120px' }}
      pt={{ base: '32px', lg: '42px' }}
      bg="brand.egg"
    >
      <Text fontSize="32px" fontFamily="roboto" textAlign="center" pb="24px">
        The simple and affordable solution
      </Text>
      <Flex justify="center">
        <CanyouLogo width={260} />
      </Flex>
      <Heading
        as="h3"
        fontSize="36px"
        textAlign="center"
        px={{ base: 4, lg: '200px' }}
        pt={{ base: '20px', lg: '32px' }}
        pb={{ base: '55px', lg: '140px' }}
      >
        <Box as="span" color="brand.blue">
          Easy{' '}
        </Box>
        <Box as="span">and</Box>
        <Box as="span" color="brand.blue">
          {' '}
          secure{' '}
        </Box>
        <Box as="span">client identity verification</Box>
      </Heading>
      {benefitDetails.map(
        ({ heading, body, images, subHeading, isOrderReversed }) => (
          <BenefitSection
            key={heading}
            heading={heading}
            body={body}
            images={images}
            subHeading={subHeading}
            isOrderReversed={isOrderReversed}
          />
        ),
      )}
    </Box>
  );
};
